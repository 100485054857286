import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import Nomination from './modules/nomination';
import Loading from './modules/loading';
import Session from './modules/session';
import Snackbar from './modules/snackbar';
import Error from './modules/error';
import User from './modules/user';
import Post from './modules/post';
import Valhalla from './modules/valhalla';

Vue.use(Vuex);

const persistedStateData = createPersistedState({
  paths: ['Session.email', 'Session.token'],
});

export default new Vuex.Store({
  modules: {
    User,
    Post,
    Loading,
    Session,
    Snackbar,
    Error,
    Nomination,
    Valhalla,
  },
  plugins: [persistedStateData],
});
