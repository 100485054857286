import Valhalla from '@/api/resources/valhalla';

const state = {
  valhallaId: Number,
  valhallaNominations: [],
  valhallaWinners: [],
  showFinishValhallaModal: false,
  likeSuccess: false,
};

const getters = {
  getValhallaNominations: () => state.valhallaNominations,
  getValhallaId: () => state.valhallaId,
  getValhallaWinners: () => state.valhallaWinners,
  showFinishValhallaModal: () => state.showFinishValhallaModal,
  getLikeSuccess: (state) => state.likeSuccess,
};

const actions = {
  saveValhallaNominations({ commit }, payload) {
    commit('setValhallaNominations', payload);
  },
  fetchValhallaNominations({ commit, dispatch }) {
    dispatch('initLoading');
    Valhalla.index().then(({ id, nominations }) => {
      commit('setValhallaNominations', nominations);
      commit('setValhallaId', id);
      dispatch('refreshUser');
      dispatch('removeLoading');
    });
  },
  finishValhalla({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Valhalla.finish(payload).then((response) => {
      commit('setValhallaWinners', response);
      dispatch('showFinishValhallaModal');
      dispatch('removeLoading');
    });
  },
  fetchValhallaWinners({ commit, dispatch }) {
    if (state.valhallaWinners.length) return;

    dispatch('initLoading');
    Valhalla.getWinners().then((response) => {
      dispatch('removeLoading');
      commit('setValhallaWinners', response);
    });
  },
  showFinishValhallaModal({ commit }) {
    commit('showFinishValhallaModal');
  },
  async doNominationLike({ dispatch, commit }, payload) {
    dispatch('initLoading');
    await Valhalla.like(payload).then((response) => {
      if (response.liked) {
        commit('setLikeSuccess', true);
      } else {
        commit('setLikeSuccess', false);
      }
    });
    dispatch('removeLoading');
    dispatch('fetchValhallaNominations');
  },
};

const mutations = {
  setValhallaNominations($state, payload) {
    const stateCopy = $state;
    stateCopy.valhallaNominations = payload;
  },
  setValhallaId($state, payload) {
    const stateCopy = $state;
    stateCopy.valhallaId = payload;
  },
  setValhallaWinners($state, payload) {
    const stateCopy = $state;
    stateCopy.valhallaWinners = payload;
  },
  showFinishValhallaModal($state) {
    const stateCopy = $state;
    stateCopy.showFinishValhallaModal = true;
  },
  setLikeSuccess($state, bool) {
    const stateCopy = $state;
    stateCopy.likeSuccess = bool;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
