import Vue from 'vue';
import firebase from 'firebase';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
};

firebase.initializeApp(firebaseConfig);
Vue.prototype.$firebase = firebase;

export const logEvent = (event, params) => {
  if (process.env.NODE_ENV !== 'development') firebase.analytics().logEvent(event, params);
};

export const logScreen = (path) => {
  if (process.env.NODE_ENV !== 'development') firebase.analytics().setCurrentScreen(path);
};

export default firebase;
