<!-- eslint-disable max-len -->
<template>
  <div>
    <v-app-bar
      color="background"
      elevation="0"
      height="114"
    >
      <div class="d-flex align-center toolbar">
        <div class="toolbar-logo">
          <v-img
            :alt="$t('components.toolbar.logo_alt')"
            class="shrink mr-2"
            contain
            src="@/assets/img/retro2x.png"
            width="200"
          />
        </div>
        <div class="toolbar-links-container">
          <div>
            <a
              :class="`toolbar-item ${isActive('retro')}`"
              href="/retro"
            >
              {{ $t('components.toolbar.posts') }}
            </a>
            <a
              :class="`toolbar-item ${isActive('valhalla')} ${isActive('indicate')} ${isActive('nominees')} ${isActive('winners')}`"
              href="/valhalla"
              @click="trackValhallaBtn"
            >
              {{ $t('components.toolbar.valhalla') }}
            </a>
            <a
              :class="`toolbar-item disabled ${isActive('')}`"
              href="#"
            >
              {{ $t('components.toolbar.history') }}
            </a>
            <a
              :class="`toolbar-item`"
              @click="logout"
            >
              {{ $t('components.toolbar.exit') }}
            </a>
          </div>
          <avatar
            :class="`toolbar-item-avatar`"
            :url="currentUser.photo_url"
            avatar-type="toolbar-border"
          />
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <navigation-drawer v-model="drawer" />
    </v-navigation-drawer>
    <v-divider />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { trackEvent } from '../../plugins/mixpanel';

export default {
  name: 'ToolbarNav',

  components: {
    Avatar: () => import('@/components/globals/Avatar.vue'),
    NavigationDrawer: () => import('./NavigationDrawer.vue'),
  },

  data() {
    return { drawer: false };
  },

  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },

  methods: {
    ...mapActions([
      'logout',
    ]),

    isActive(routeName) {
      if (this.$route.name === routeName) {
        return 'active';
      }
      return '';
    },
    trackValhallaBtn() {
      trackEvent('clicou_ir_para_valhalla');
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/scss/components/navs/toolbar.scss"/>
