import ApiService from '../api.service';

const UsersResource = {
  googleLogin: (params) => ApiService.post('v1/users/sign_in', params),
  show: () => ApiService.get('v1/users/me'),
  register: (params) => ApiService.post('v1/users', params),
  users: (params) => ApiService.get(`v1/users/?can_be_indicated_by=${params.id}`),
};

export default UsersResource;
