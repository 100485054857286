import Vue from 'vue';
import Router from 'vue-router';
import { logScreen } from '../plugins/firebase';

Vue.use(Router);

const routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/retro',
    },
    {
      path: '/valhalla',
      name: 'valhalla',
      // beforeEnter: () => {
      //   window.location.href = 'https://retro.jera.com.br/elections';
      // },
      component: () => import('@/views/Valhalla.vue'),
      meta: {
        needToolbar: true,
        requiresAuth: true,
      },
    },
    {
      path: '/valhalla/indicate',
      name: 'indicate',
      component: () => import('@/views/Indicate.vue'),
      meta: {
        needToolbar: true,
        requiresAuth: true,
      },
    },
    {
      // path: 'valhalla/indicate/indicated',
      path: '/valhalla/nominees',
      name: 'nominees',
      component: () => import('@/views/Nominees.vue'),
      meta: {
        needToolbar: true,
        requiresAuth: true,
      },
    },
    {
      path: '/retro',
      name: 'retro',
      component: () => import('@/views/Retro.vue'),
      meta: {
        needToolbar: true,
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        icon: 'none',
      },
    },
    {
      path: '/valhalla/winners',
      name: 'winners',
      component: () => import('@/views/Winners.vue'),
    },
  ],
});

routes.beforeEach(async (_, from, next) => {
  logScreen(from.path);
  next();
});

export default routes;
