import '@mdi/font/scss/materialdesignicons.scss';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#0DB551',
        background: '#f7f7f7',
        shadowPurple: '#B475D852',
        shadowGreen: '#67B587',
        shadowGreenBtn: '#0B99444D',
        shadowLogin: '#0DB55154',
        shadowOrange: '#99440B',
        textColor: '#0000DE',
        cardBackgroud: '#F1F0F2',
        postBackground: '#FAFAFA',
        postTodo: '#7c12cd',
        postGood: '#0DB551',
        postNotice: '#d43bff',
        postImprove: '#ff7212',
        textInputPost: '#E4E3E6',
        postCheckText: '#111111',
        error: '#E69E9E',
        editPostShadow: '#505D6580',
        editPostText: '#111111DE',
        loginSubtitle: '#000000',
        notificationError: '#ff5252',
      },
    },
  },
  options: {
    customProperties: true,
  },
});
