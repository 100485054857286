import Post from '@/api/resources/posts';

const state = {
  goodPosts: [],
  improvePosts: [],
  todosPosts: [],
  noticePosts: [],
  likeSuccess: false,
};

const getters = {
  getGoodPosts: (state) => state.goodPosts,
  getImprovePosts: (state) => state.improvePosts,
  getTodosPosts: (state) => state.todosPosts,
  getNoticePosts: (state) => state.noticePosts,
  getLikeSuccess: (state) => state.likeSuccess,
};

const actions = {
  fetchGoodPosts({ dispatch, commit }) {
    Post.index({ by_category: 'good' }).then((response) => {
      commit('setGoodPosts', response);
      dispatch('removeLoading');
    });
  },

  fetchImprovePosts({ dispatch, commit }) {
    Post.index({ by_category: 'improve' }).then((response) => {
      commit('setImprovePosts', response);
      dispatch('removeLoading');
    });
  },

  fetchTodosPosts({ dispatch, commit }) {
    Post.index({ by_category: 'todo' }).then((response) => {
      commit('setTodosPosts', response);
      dispatch('removeLoading');
    });
  },

  fetchNoticePosts({ dispatch, commit }) {
    Post.index({ by_category: 'notice' }).then((response) => {
      commit('setNoticePosts', response);
      dispatch('removeLoading');
    });
  },

  createPost({ dispatch }, payload) {
    dispatch('initLoading');

    Post.create(payload).then(() => {
      dispatch('fetchGoodPosts');
      dispatch('fetchImprovePosts');
      dispatch('removeLoading');
    });
  },

  createLike({ dispatch, commit }, payload) {
    dispatch('initLoading');

    Post.like(payload).then(() => {
      commit('setLikeSuccess', true);
      dispatch('removeLoading');
    });

    commit('setLikeSuccess', false);
  },

  destroyLike({ dispatch, commit }, payload) {
    dispatch('initLoading');

    Post.unlike(payload).then(() => {
      commit('setLikeSuccess', true);
      dispatch('removeLoading');
    });

    commit('setLikeSuccess', false);
  },

  todos({ dispatch, commit }, payload) {
    dispatch('initLoading');

    Post.todo(payload).then(() => {
      commit('setTodosPosts');
      dispatch('fetchImprovePosts');
      dispatch('fetchTodosPosts');
      dispatch('removeLoading');
    });
  },

  done({ dispatch, commit }, payload) {
    dispatch('initLoading');

    Post.done(payload).then(() => {
      commit('setTodosPosts');
      dispatch('fetchImprovePosts');
      dispatch('fetchTodosPosts');
      dispatch('removeLoading');
    });
  },

  deletePost({ dispatch }, payload) {
    dispatch('initLoading');

    Post.delete(payload).then(() => {
      dispatch('fetchImprovePosts');
      dispatch('fetchTodosPosts');
      dispatch('fetchGoodPosts');
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setGoodPosts($state, payload) {
    const stateCopy = $state;
    stateCopy.goodPosts = payload;
  },

  setImprovePosts($state, payload) {
    const stateCopy = $state;
    stateCopy.improvePosts = payload;
  },

  setTodosPosts($state, payload) {
    const stateCopy = $state;
    stateCopy.todosPosts = payload;
  },

  setNoticePosts($state, payload) {
    const stateCopy = $state;
    stateCopy.noticePosts = payload;
  },

  setLikeSuccess($state, bool) {
    const stateCopy = $state;
    stateCopy.likeSuccess = bool;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
