import mixpanel from 'mixpanel-browser';
// import Vue from 'vue';
import user from '../store/modules/user';

const currentUser = user.state.user;

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);

if (process.env.NODE_ENV !== ('staging' || 'production')) mixpanel.set_config({ debug: true });

// Set this to a unique identifier for the user performing the event.
// eg: their ID in your database or their email address.
mixpanel.identify(currentUser.email);

export const trackEvent = (eventName) => {
  if (process.env.NODE_ENV !== 'development') mixpanel.track(eventName);
};

export default mixpanel;
