<template>
  <v-app>
    <toolbar-nav v-if="needToolbar" />
    <loading />
    <v-main id="main-app">
      <v-container
        class="app-container"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
    <snackbar />
    <!-- <v-footer app></v-footer> -->
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ToolbarNav from './components/navs/ToolbarNav.vue';
import Loading from './components/feedbacks/Loading.vue';
import Snackbar from './components/feedbacks/Snackbar.vue';

export default {
  name: 'App',
  components: {
    ToolbarNav,
    Loading,
    Snackbar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasSession',
      'currentUser',
      'hasMessage',
    ]),
    needToolbar() {
      return (this.$route.meta.needToolbar || this.hasSession);
    },
  },
  watch: {
    hasSession(hasSession) {
      if (!hasSession) {
        this.$router.push('/login');
      } else {
        this.$router.push('/retro');
      }
    },
  },

  beforeMount() {
    this.refreshUser();
  },

  methods: {
    ...mapActions([
      'refreshUser',
    ]),
  },
};
</script>
<style lang="scss" scoped>
  #main-app {
    background-color: $background;

    .app-container {
      height: 100%;
    }
  }
</style>
