import Valhalla from '@/api/resources/valhalla';

const state = {
  selectedNominations: [],
};

const getters = {
  getSelectedNominations: () => state.selectedNominations,
};

const actions = {
  saveSelectedNominations({ commit }, payload) {
    commit('setSeletectedNominations', payload);
  },
  async postNominations({ commit, dispatch }, payload) {
    const data = payload.nominations.map((nomination) => ({
      id: nomination.nominated.id,
      justification: nomination.justification,
    }));

    dispatch('initLoading');
    await Valhalla.doNominations({ ...payload, nominations: data }).then(() => {
      commit('setSeletectedNominations', []);
      dispatch('refreshUser');
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setSeletectedNominations($state, payload) {
    const stateCopy = $state;
    stateCopy.selectedNominations = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
