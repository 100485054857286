import ApiService from '../api.service';

const PostsResource = {
  index: (params) => ApiService.query('v1/posts', params),
  create: (params) => ApiService.post('v1/posts', params),
  like: (params) => ApiService.post(`v1/posts/${params.id}/like`),
  unlike: (params) => ApiService.delete(`v1/posts/${params.id}/unlike`),
  todo: (params) => ApiService.put(`v1/posts/${params.id}/todo`),
  done: (params) => ApiService.put(`v1/posts/${params.id}/mark_as_done`),
  delete: (params) => ApiService.delete(`v1/posts/${params.id}`),
};

export default PostsResource;
