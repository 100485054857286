<template>
  <div
    v-if="hasLoading"
    class="m-app-loader"
  >
    <v-img
      src="@/assets/img/loading_animation_jera.gif"
      alt="loading_animation"
      max-width="100px"
      class="loader"
    />
  </div>
</template>
<script>

import { mapState } from 'vuex';

export default {
  name: 'Loading',
  computed: {
    ...mapState([
      'Loading',
    ]),
    hasLoading() {
      return this.Loading.loading;
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/feedbacks/loading.scss" scoped>
