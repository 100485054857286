import ApiService from '../api.service';

const ValhallaResource = {
  doNominations: (params) => ApiService.post(`/v1/valhallas/${params.valhalla_id}/nominations`, params),
  index: () => ApiService.query('/v1/valhallas/current'),
  finish: () => ApiService.put('/v1/valhallas/finish_current'),
  getWinners: () => ApiService.get('/v1/valhallas/winners'),
  like: (params) => ApiService.post(`/v1/valhallas/${params.valhalla_id}/nominations/${params.nominated_id}/toggle_like`),
};

export default ValhallaResource;
